export {appCallbackFolgen, appCallbackEntfolgen}

function appCallbackFolgen(parent, bewerterId) {
    sendCallback(createCallbackAppElement(true, bewerterId), parent);
}

function appCallbackEntfolgen(parent, bewerterId) {
    sendCallback(createCallbackAppElement(false, bewerterId), parent);
}

function sendCallback(element, parent) {
    parent.insertAdjacentElement("afterend", element);
}

function createCallbackAppElement(folgen, bewerterId) {
    const callbackElement = document.createElement("callback-app");
    const paramElement = document.createElement("callback-param");
    callbackElement.appendChild(paramElement);

    callbackElement.setAttribute("callback", folgen ? "followBookseller" : "unfollowBookseller");
    paramElement.setAttribute("key", "id");
    paramElement.setAttribute("value", bewerterId);

    return callbackElement;
}
