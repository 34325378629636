import {appCallbackEntfolgen, appCallbackFolgen} from "./app-callback";

class BuchhaendlerFolgen extends HTMLElement {

  async connectedCallback() {
    this.token = 'session_token';
    this.button = this.querySelector('.buchhaendler-folgenbutton');
    this.text = this.querySelector('.button-text');
    this.icon = this.querySelector('.button-icon');

    this.button.addEventListener('click', this.initBuchhaendlerFolgen.bind(this));
    this.toggleText();
  }

  async initBuchhaendlerFolgen(e) {
    if (this.istAngemeldet && this.sindAppCallbacksAktiv) {
      if (this.folgtBewerter) {
        appCallbackEntfolgen(this.button, this.dataset.buchhaendlerId);
      } else {
        appCallbackFolgen(this.button, this.dataset.buchhaendlerId);
      }
      this.folgtBewerter = !this.folgtBewerter;
      this.toggleText();
      return;
    }
    if (this.istAngemeldet) {
      this.updateStatus(e.target, 'laden');
      this.response = await this.ajax(this.dataset.api.replace('{bewerterId}', this.dataset.buchhaendlerId));
      if (this.response.status === 'success') {
        this.folgtBewerter = !this.folgtBewerter;
        this.toggleText();
        this.updateStatus(e.target, 'erfolg');
      } else if (this.response.status === 'login') {
        this.redirectLogin();
      } else if (this.response.status === 'error') {
        this.updateStatus(e.target, 'fehler');
      }
    } else {
      this.redirectLogin();
    }
  }

  toggleText() {
    this.text.innerHTML = this.folgtBewerter ? 'Folgend' : 'Folgen';
    this.button.setAttribute('interaction', this.folgtBewerter ? 'buchhaendler-entfolgen' : 'buchhaendler-folgen');
    if (this.icon) {
        this.icon.classList.remove('element-icon-herz-ausgefuellt', 'element-icon-herz');
        this.icon.classList.add(this.folgtBewerter ? 'element-icon-herz-ausgefuellt' : 'element-icon-herz');
    }
  }

  updateStatus(element, wert) {
    if (wert === 'erfolg') this.removeAttributes(element, ['data-loading', 'disabled']);
    if (wert === 'laden') this.setAttributes(element, {'data-loading': '', 'disabled': ''});
    if (wert === 'fehler') this.initWarnmeldung(element);
  }

  initWarnmeldung(element) {
    this.removeAttributes(element, ['data-loading', 'disabled']);
    this.warnHinweis = this.querySelector('.hinweis');
    this.warnHinweis.setAttribute('open', '');
  }

  /**
   * Ajax/Fetch für den buchhaendler-folgen-controller
   * @param {string} uri - URI zur Resource
   * @returns {object}
   */
  ajax(uri) {
    const options = {
      method: this.folgtBewerter ? 'DELETE' : 'PUT',
    };
    return fetch(uri, options)
        .then((response) => {
          if (response.ok) {
            return {status: "success"};
          } else if (response.status === 401) {
            return {status: "login"};
          } else {
            return {status: "unknown"}
          }
        })
        .catch((error) => {
          console.error(error);
          return {status: "error"}
        })
  }

  redirectLogin() {
    const location = window.location;
    const source = encodeURIComponent(location.pathname + location.search + location.hash);
    window.location.href = this.dataset.login.replace('{sourceUrl}', source);
  }

  setAttributes(element, attributes) {
    for(const key in attributes) {
      element.setAttribute(key, attributes[key]);
    }
  }

  removeAttributes(element, attributes) {
    attributes.forEach(attr => element.removeAttribute(attr));
  }

  set folgtBewerter(neuerWert) {
    if (neuerWert) {
      this.button.setAttribute('selected', 'selected');
    } else {
      this.button.removeAttribute('selected');
    }
  }

  get folgtBewerter() {
    return this.button.hasAttribute('selected');
  }

  get istAngemeldet() {
    return this.dataset.kundeAngemeldet === 'true';
  }

  get sindAppCallbacksAktiv() {
    return this.dataset.appCallbacksActive === 'true';
  }

}

if (!customElements.get("buchhaendler-folgen")) {
  customElements.define("buchhaendler-folgen", BuchhaendlerFolgen);
}
